<template>
  <v-navigation-drawer
    v-model="inputValue"
    fixed
    right
    temporary>
    <v-list>
      <v-list-tile
        v-for="(route, i) in menu"
        :key="i"
        @click="onClick(route)">
        <v-list-tile-title v-text="route.text" />
      </v-list-tile>
    </v-list>
    <v-spacer />
    <v-btn icon @click="inputValue = false">
      <v-icon>exit_to_app</v-icon>
    </v-btn>
  </v-navigation-drawer>
</template>

<script>
  import { createNamespacedHelpers, mapMutations } from 'vuex'
  const { mapGetters } = createNamespacedHelpers('app')

  export default {
    computed: {
      ...mapGetters(['menu']),
      inputValue: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.setDrawer(val)
        }
      },
    },

    methods: {
      ...mapMutations(['setDrawer', 'toggleDrawer']),
      onClick (route) {
        this.$router.push(route.path)
        this.inputValue = false
      }
    }
  }
</script>

<style scoped>
.v-navigation-drawer {
  display: flex;
  flex-direction: column;
}
</style>